import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { mainAction } from "../../redux/actions/interactionsActions";
import { getOfficePreferences } from "../../redux/actions/officeSettingsActions";
import { addTextTemplate } from "../../redux/actions/customTemplatesActions";
import { toast } from "react-toastify";

class CreateCustomTemplate extends Component {
  constructor(props) {
    super(props);
    this.createTemplateButton = React.createRef(null);

    this.state = {
      toggle: false,
      officePhone: this.props.displayedOfficePhone,
      inputTitle: "",
      inputContent: "",
      inputCategory: "select"
    };
    this.disableSaveButton = this.disableSaveButton.bind(this);
    this.onClickClose = this.onClickClose.bind(this);
    this.onClickCreateTemplate = this.onClickCreateTemplate.bind(this);
  }

  disableSaveButton = () => {
    if (!this.props.allowCreateTemplate) {
      return true;
    }
    if (
      this.state.inputCategory === "select" ||
      this.state.inputTitle === "" ||
      this.state.inputContent === ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  onClickClose = () => {
    this.setState({
      inputTitle: "",
      inputContent: "",
      inputCategory: "select"
    });
    this.props.onClickCreateNew();
  };

  onClickCreateTemplate = () => {
    this.createTemplateButton.current.disabled = true;
    this.createTemplateButton.current.classList.add("loading-spinner");

    try {
      this.props.actions.addTextTemplate(
        this.props.officeAssociateId,
        this.props.loggedInAssociateId,
        this.state.inputContent,
        this.state.inputTitle,
        this.state.inputCategory
      );
    } catch (error) {
      this.createTemplateButton.current.classList.remove("loading-spinner");
      return;
    }

    setTimeout(() => {
      this.createTemplateButton.current.classList.remove("loading-spinner");
      if (!this.props.templateAddStatus.includes("Error")) {
        this.onClickClose();
        this.disableSaveButton();
      } else {
        this.setState({
          inputTitle: ""
        });
        this.disableSaveButton();
      }
    }, 2000);
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.templateAddStatus !== this.props.templateAddStatus ||
      prevProps.allowCreateTemplate !== this.props.allowCreateTemplate
    ) {
      if (this.props.templateAddStatus !== "") {
        if (this.props.templateAddStatus.includes("Error")) {
        } else {
          toast.info(this.props.templateAddStatus, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000
          });
        }
      }
      this.forceUpdate();
    }
  }

  render() {
    return (
      <div className="-oneX -oneX-cards-container--padding-md">
        <div class="-oneX">
          <label for="conv-textfield" class="-oneX-textfield__label">
            Category*
          </label>
          <select
            id="conv-categoryfield"
            type="text"
            className="-oneX-dropdown dropdown-row"
            aria-label="select Category"
            aria-required="true"
            value={this.state.inputCategory}
            onChange={(event) =>
              this.setState({ inputCategory: event.target.value })
            }
          >
            <option value="select">Please Select</option>
            <option value="Claims">Claims</option>
            <option value="Drive Safe & Save">Drive Safe & Save</option>
            <option value="Notice of Document(s) Needed">
              Notice of Document(s) Needed
            </option>
            <option value="Notice of Payment Due">Notice of Payment Due</option>
            <option value="Notice of Premium Change">
              Notice of Premium Change
            </option>
            <option value="Policy Review">Policy Review</option>
            <option value="Quotes/Leads">Quotes/Leads</option>
            <option value="Other">Other</option>
          </select>
        </div>
        <div class="-oneX">
          {/* <div class="-oneX-widget__err-text"> */}
          {/* <div class={this.props.templateAddStatus.includes("Error") ? "-oneX-widget__err-text" : "-oneX"}> */}
          <label for="conv-textfield" class="-oneX-textfield__label">
            Title*
          </label>
          {this.props.templateAddStatus.includes("Error") ? (
            <div class="-oneX-widget--err">
              <input
                value={this.state.inputTitle}
                onChange={(event) => {
                  this.setState({ inputTitle: event.target.value });
                }}
                id="name"
                type="text"
                placeholder="Please update to a different title"
                class="-oneX-textfield--floating-input"
                aria-invalid="true"
                aria-describedby="name-err"
              />
              <div
                id="name-err"
                class="-oneX-widget__err-text"
                aria-live="assertive"
              >
                {this.props.templateAddStatus}
              </div>
            </div>
          ) : (
            <input
              id="conv-titlefield"
              type="text"
              value={this.state.inputTitle}
              onChange={(event) => {
                this.setState({ inputTitle: event.target.value });
              }}
              class="-oneX-textfield--conversational-input"
            />
          )}
        </div>
        <div class="-oneX-col-md-14 ">
          <label for="conTA" class="-oneX-textfield__label">
            Content*
          </label>
          <textarea
            id="conv-contentfield"
            class="-oneX-textfield--conversational-input"
            placeholder="To protect sensitive personal information, do not include full SSN, TIN, SIN, Driver's License #, financial account numbers, credit/debit card numbers, Personal Health Information or any medical information."
            value={this.state.inputContent}
            maxLength={280}
            onChange={(event) => {
              this.setState({ inputContent: event.target.value });
            }}
          ></textarea>
          <div class="-oneX-textarea-charRemaining">
            ({280 - this.state.inputContent.length}) characters remaining
          </div>
        </div>
        <br />
        <div className="-oneX-row -oneX-no-gutters bottom-nav ">
          <div className="-oneX-col-6">
            {/* <div className="template-status">
              {this.props.templateAddStatus.includes("Error")
                ? ""
                : this.props.templateAddStatus}
            </div> */}
          </div>
          <button
            id="cancelBtn"
            className="-oneX-btn-secondary -oneX-btn-small"
            onClick={(e) => this.onClickClose()}
          >
            Close
          </button>
          &nbsp;&nbsp;&nbsp;
          <button
            id="saveBtn"
            ref={this.createTemplateButton}
            className="-oneX-btn-primary -oneX-btn-small"
            disabled={this.disableSaveButton()}
            onClick={(e) => this.onClickCreateTemplate()}
          >
            Save
          </button>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    displayedOfficePhone:
      state.officeSettingsReducer.officePhone.displayedOfficePhone,
    loggedInAssociateId: state.userInfoReducer.loggedInAssociateId,
    officeAssociateId: state.userInfoReducer.officeAssociateId,
    templateAddStatus: state.customTemplatesReducer.templateAddStatus,
    allowCreateTemplate: state.customTemplatesReducer.allowCreateTemplate
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        getOfficePreferences,
        mainAction,
        addTextTemplate
      },
      dispatch
    )
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateCustomTemplate);
