import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { mainAction } from "../../redux/actions/interactionsActions";
import { getOfficePreferences } from "../../redux/actions/officeSettingsActions";
import {  
  clearCustomTemplateValues
} from "../../redux/actions/customTemplatesActions";
import CustomTemplateItem from "./CustomTemplateItem";
import CreateCustomTemplate from "./CreateCustomTemplate";
import { toast } from "react-toastify";

class WordTrackDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      createTemplateToggle: false,
      officePhone: this.props.displayedOfficePhone,
      selectedCategory: "",
      panel: {
        visible: "",
        expansionHeight: { height: "41.6px" }
      }
    };
  }

  componentDidMount() {
    //This is needed by oneX onLoad of the object, the content panel must be hidden, then oneX toggles the class.
    this.setState({ panel: { visible: " -oneX-hidden" } });
    if (window.oneX) {
      let oneXItem = {};
      for (oneXItem in this.refs) {
        window.oneX.addElement(this.refs[oneXItem]);
      }
      window.oneX.ExpansionPanel.init();
    }
  }

  setVisible(event) {
    //This is needed by oneX onClick of the object
    this.setState({
      panel: {
        visible: this.state.panel.visible !== ":visible" ? ":visible" : ""
      }
    });
  }

  onClickHandler = (track, title, category) => {
    const _wordTrack = track;
    if (typeof _wordTrack === "string") {
      const _newWordTrack = _wordTrack
        .replace(/(&lt;)/g, "<")
        .replace(/(&gt;)/g, ">")
        .replace(/(&amp;)/g, "&")
        .replace(/<office phone number>/g, this.state.officePhone)
        .replace(/<office phone#>/g, this.state.officePhone);

      const { cursorPos } = this.props.textMessageBox;
      const _startMessage = this.props.messageBody
        ? this.props.messageBody.slice(0, cursorPos.start)
        : "";
      const _endMessage = this.props.messageBody
        ? this.props.messageBody.slice(cursorPos.end)
        : "";
      this.props.actions.mainAction("SELECTED_TEMPLATE", {
        selectedTemplate: {
          type: "corporate",
          data: {
            category: category,
            title: title,
            content: _startMessage + _newWordTrack + _endMessage
          }
        }
      });
      this.props.actions.mainAction(
        "UPDATE_CHAT",
        _startMessage + _newWordTrack + _endMessage
      );

      this.hideDrawer();
      // Give the document focus
      setTimeout(() => {
        const _textArea = document.getElementById("conTA");
        _textArea.focus();
      }, 1000);
    }
  };

  hideDrawer = () => {
    this.props.actions.clearCustomTemplateValues("CLEAR_CUSTOM_TEMPLATE", null);
    window.oneX.Drawer.hideDrawer();
  };

  keyPressed = (event, track, title, category) => {
    if (event.key === "Enter" || event.key === " ") {
      this.onClickHandler(track, title, category);
    }
    if (event.key === "Esc") {
      this.hideDrawer(event);
    }
  };

  toggleExpansion = (category) => {    
    if (this.state.selectedCategory === "") {
      this.setState({ selectedCategory: category });
    } else {
      this.setState({ selectedCategory: "" });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.templateCurrentStatus !== this.props.templateCurrentStatus ||
      prevProps.customTextTemplates !== this.props.customTextTemplates ||
      prevProps.allowCreateTemplate !== this.props.allowCreateTemplate
    ) {
      if (this.props.templateCurrentStatus !== "") {
        if (this.props.templateCurrentStatus.includes("Error")) {
          toast.error(this.props.templateCurrentStatus, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000
          });
        } else {
          toast.info(this.props.templateCurrentStatus, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000
          });
        }
      }
      this.forceUpdate();
    }
  }

  onClickCreateNew = () => {
    this.setState({ createTemplateToggle: false });
  };

  render() {
    const { wordTracks } = this.props;
    
    return (
      <>
        <section
          ref={"wordTrackDrawer"}
          id="WordTrackDrawer"
          className="-oneX -oneX-drawer-container"
          style={{ display: "none" }}
        >
          <div role="dialog" aria-labelledby="WT_dialogLnkHdr">
            <div
              id="WT_dialogLnkHdr"
              className="textTemplatesHeaderBkGround"
              tabIndex="-1"
            >
              <div className="-oneX-icon-container -oneX-icon--interactive textTemplatesHeaderBkGround">
                <div
                  className="-oneX-icon drawer-icon chevronDiv"
                  data-icon="web_ui_chevron"
                  aria-label="Close Drawer"
                  onClick={this.hideDrawer}
                ></div>

                <div className="officeSettingHeaderTextGroupDiv">
                  {/* <span className="officeSettingHeaderTitleSmall">
                    SF Connect
                  </span> */}
                  <h3 className="-oneX-drawer-header ">Text Templates</h3>

                  <div className="sub-title-discription">
                    To avoid a carrier flagging the message as SPAM and blocking
                    delivery, make sure to modify the populated template before
                    sending.
                    
                    Texting for marketing is prohibited.
                  </div>
                </div>
              </div>
            </div>

            {/* Custom Templates(Start)       */}
            <div className="-oneX -oneX-container">
              <hr className="-oneX-hr" />
              <div className="-oneX-panel--expansion--nested ">
                <div className="-oneX-panel-control">
                  <button
                    className="-oneX-panel-button"
                    aria-expanded="false"
                    aria-controls="nestedCustomDetailsDiv"
                    type="button"
                  >
                    Custom Templates
                  </button>

                  <span className="-oneX-panel-value"></span>
                  <span className="-oneX-panel__chevron-down"></span>
                  {!this.state.createTemplateToggle &&
                  this.props.allowCreateTemplate ? (
                    <a
                      className="-oneX-btn-secondary__anchor -oneX-btn-small"
                      href="javascript:void(0);"
                      onClick={(event) => {
                        this.setState({
                          createTemplateToggle: !this.state.createTemplateToggle
                        });
                      }}
                    >
                      Create New Template
                    </a>
                  ) : !this.props.allowCreateTemplate &&
                    this.props.templateAddStatus &&
                    !this.props.templateAddStatus.includes(
                      "success"
                    ) ? (
                    <div className="template-error-status">
                      {this.props.templateAddStatus}
                    </div>
                  ) : null}
                </div>

                <div className="-oneX-row -oneX-no-gutters bottom-nav ">
                  <div className="-oneX-col-8" />
                  {this.state.createTemplateToggle ? (
                    <CreateCustomTemplate
                      onClickCreateNew={this.onClickCreateNew}
                    />
                  ) : null}
                </div>

                <div
                  id="nestedCustomDetailsDiv"
                  tabIndex="0"
                  className="-oneX-panel-content -oneX-hidden"
                >

                {/* Category block*/}
                {this.props.customTextTemplates &&
                  this.props.customTextTemplates.length > 0 &&
                  this.props.customTextTemplates.map(
                    (customTextTemplateCategory, index) => {
                      return (
                        
                          <div className="-oneX-panel--expansion--nested ">
                            <div className="-oneX-panel-control"  onClick={(event) => {
                                  this.toggleExpansion(
                                    customTextTemplateCategory.category
                                  );
                                }}>
                              <button                               
                                className="-oneX-panel-button"
                                aria-expanded="false"
                                aria-controls={"category_" +index}
                                type="button"
                              >
                                {customTextTemplateCategory.category}
                              </button>
                              <span className="-oneX-panel-value"></span>
                              <span className="-oneX-panel__chevron-down"></span>
                            </div>

                            {/* Title & Content block */}
                            <div
                              id={"category_" +index}
                              tabIndex={index}
                              className="-oneX-panel-content"
                            >
                              {customTextTemplateCategory.category ===
                                this.state.selectedCategory &&
                                customTextTemplateCategory.items.map(
                                  (customTemplate, templateIndex) => {
                                    return (
                                      <CustomTemplateItem
                                        key={templateIndex}
                                        item={customTemplate}
                                        index={templateIndex}
                                      />
                                    );
                                  }
                                )}
                            </div>
                            {/* Title & Content block */}
                          </div>
                        
                      ); //return block ends
                    }
                  )}
                  </div>
              </div>
              <hr className="-oneX-hr" />
            </div>

            {/* Ends */}

            {/* Static Templates(Start)       */}
              <div className="-oneX -oneX-container">  
                  <hr className="-oneX-hr"/>
                  <div className="-oneX-panel--expansion--nested">
                    
                    <div className="-oneX-panel-control">
                      <button className="-oneX-panel-button" aria-expanded="false" aria-controls="nestedDetailsDiv" type="button">
                      Corporate Templates</button>   
                      <span className="-oneX-panel-value"></span>                   
                      <span className="-oneX-panel__chevron-down"></span>
                    </div>
                    
                    <div id="nestedDetailsDiv" tabIndex="0" className="-oneX-panel-content -oneX-hidden">

                    {wordTracks.list.map((list, index) => {
                      return (
                        <>
                          {list.enabled ? (
                              // category block
                              <div className="-oneX-panel--expansion--nested">
                                <div className="-oneX-panel-control">
                                  <button className="-oneX-panel-button" aria-expanded="false" aria-controls={
                                      "category_" + list.title + "_" + index
                                    } type="button">
                                    {list.title}</button>                        
                                  <span className="-oneX-panel-value"></span>
                                  <span className="-oneX-panel__chevron-down"></span>
                                </div>
                                
                                <div id={
                                      "category_" + list.title + "_" + index
                                    } tabIndex="0" className="-oneX-panel-content -oneX-hidden">
                                  
                                  {/* Template Title & Content */}
                                  {list.items.map((template, templateIndex) => {
                                    return (
                                  <div className="-oneX-panel--expansion--nested">
                                    <div className="-oneX-panel-control">                                      
                                    <b> {template.title}</b>
                                    </div>
                                  
                                    <div id={
                                              "template_" +
                                              template.title +
                                              "_" +
                                              templateIndex
                                            } tabIndex="0" className="-oneX-panel-content">
                                      {template.track}

                                      <div className="-oneX-row -oneX-no-gutters bottom-nav ">
                                        <div className="-oneX-col-10" />
                                        <a
                                          className="-oneX-link--block -oneX-link-tertiary"
                                          href="javascript:void(0);"
                                          onClick={(e) =>
                                            this.onClickHandler(template.track, template.title, list.title)
                                          }
                                        >
                                          Select
                                        </a>
                                      </div>

                                    </div>

                                  </div>
                                  );
                                })}
                                 
                                </div>
                              </div>

                           ) : null}
                           </>
                         ); //return block ends
                       })}

                    </div>
                  </div>
                  <hr className="-oneX-hr"/>
                </div>

            {/* Ends */}

            <button
              id="WT_closeButtonX"
              className="-oneX-close-X-button"
              aria-label="Close Drawer"
              onClick={this.hideDrawer}
            >
              <span className="-oneX-icon--close"></span>
            </button>
          </div>
        </section>
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    messageBody: state.interactionsReducer.currentInteraction.messageBody,
    textMessageBox: state.interactionsReducer.textMessageBox,
    displayedOfficePhone:
      state.officeSettingsReducer.officePhone.displayedOfficePhone,
    agentId: state.userInfoReducer.officeAssociateId,
    loggedInAssociateId: state.userInfoReducer.loggedInAssociateId,
    customTextTemplates: state.customTemplatesReducer.customTextTemplates,
    templateCurrentStatus: state.customTemplatesReducer.templateCurrentStatus,
    allowCreateTemplate: state.customTemplatesReducer.allowCreateTemplate,
    templateAddStatus: state.customTemplatesReducer.templateAddStatus
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        getOfficePreferences,
        mainAction,        
        clearCustomTemplateValues
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WordTrackDrawer);