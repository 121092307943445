import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  mainAction,
  fetchInteractions,
} from "../../redux/actions/interactionsActions";
import {
  CHANGE_FILTER_OPTIONS,
  SET_CURRENT_FILTER,
} from "../../redux/actions/types";

class FilterOptions extends Component {
  componentDidMount() {
    this.props.actions.fetchInteractions(
      this.props.userInfoReducer.officeAssociateId,
      this.props.userInfoReducer.currentOfficeMembers,
      undefined,
      {
        hidden: false,
        assignments: this.props.interactionsReducer.selectedAssignmentsFilter,
        returnUnassigned: this.props.interactionsReducer.returnUnassigned,
        returnInactiveAssignments : this.props.interactionsReducer.returnInactiveAssignments,
      }
    );
  }

  componentDidUpdate() {
    //Clear the New filter selection when the redux state is empty
    if (this.props.interactionsReducer.isNewlyRead) {
      this.refs.Unread.checked = false;
      this.refs.Unread.blur();
      this.props.actions.mainAction(SET_CURRENT_FILTER, {
        isButtonFilterOn: false,
        isNewFilterOn: false,
        isReadFilterOn: false,
        isRepliedToFilterOn: false,
        isFollowUpFilterOn: false,
        isHiddenFilterOn: false,
        value: "",
      });
      this.props.actions.mainAction(CHANGE_FILTER_OPTIONS, {
        isNewlyRead: false,
        endlessScroll: true,
      });
    }
    if (
      this.props.interactionsReducer.isTextFilterOn &&
      this.props.interactionsReducer.currentFilter
    ) {
      this.refs[this.props.interactionsReducer.currentFilter].checked = false;
      this.refs[this.props.interactionsReducer.currentFilter].blur();
      this.props.actions.mainAction(SET_CURRENT_FILTER, {
        isTextFilterOn: true,
        value: "",
      });
    }
  }

  onChangeFilterValue(e) {
    if (e.target.value) {
      //below if checks if clicked on filter button to perform unclick operation
      if (this.props.interactionsReducer.currentFilter === e.target.value) {
        //clear redux state
        this.props.actions.mainAction(SET_CURRENT_FILTER, {
          isButtonFilterOn: false,
          isNewFilterOn: false,
          isReadFilterOn: false,
          isRepliedToFilterOn: false,
          isFollowUpFilterOn: false,
          isHiddenFilterOn: false,
          value: "",
        });
        this.props.actions.fetchInteractions(
          this.props.userInfoReducer.officeAssociateId,
          this.props.userInfoReducer.currentOfficeMembers,
          undefined,
          {
            hidden: false,
            assignments:
              this.props.interactionsReducer.selectedAssignmentsFilter,
            returnUnassigned: this.props.interactionsReducer.returnUnassigned,
            returnInactiveAssignments: this.props.interactionsReducer.returnInactiveAssignments,
          }
        );
        //clear it out on screen
        this.refs[e.target.value].checked = false;
        this.refs[e.target.value].blur();
      } else {
        const isNewFilterOn = e.target.value === "Unread";
        const isReadFilterOn = e.target.value === "Viewed";
        const isRepliedToFilterOn = e.target.value === "Replied";
        const isFollowUpFilterOn = e.target.value === "Starred";
        const isHiddenFilterOn = e.target.value === "Hidden";
        //set redux state
        this.props.actions.mainAction(SET_CURRENT_FILTER, {
          isTextFilterOn: false,
          isButtonFilterOn: true,
          isNewFilterOn,
          isReadFilterOn,
          isRepliedToFilterOn,
          isFollowUpFilterOn,
          isHiddenFilterOn,
          textFilterLastSearch: "",
          value: e.target.value,
        });
        this.props.actions.fetchInteractions(
          this.props.userInfoReducer.officeAssociateId,
          this.props.userInfoReducer.currentOfficeMembers,
          undefined,
          {
            hidden: isHiddenFilterOn,
            unread: isNewFilterOn,
            read: isReadFilterOn,
            repliedTo: isRepliedToFilterOn,
            followUp: isFollowUpFilterOn,
            assignments:
              this.props.interactionsReducer.selectedAssignmentsFilter,
            returnUnassigned: this.props.interactionsReducer.returnUnassigned,
            returnInactiveAssignments: this.props.interactionsReducer.returnInactiveAssignments,
          }
        );
      }
    }
  }

  render() {
    return (
      <>
        <div className="-oneX-container filterPop">
          <div className="-oneX-row">
            <div className="-oneX-col-12">
              <fieldset className="-oneX-fieldset">
                <div
                  ref="filterRadio"
                  className="-oneX-radio-group -oneX--box-selector"
                  onClick={(e) => this.onChangeFilterValue(e)}
                  onKeyPress={(e) => this.onChangeFilterValue(e)}>
                  <div className="-oneX-radio-item">
                    <input
                      ref="Unread"
                      id="unreadId"
                      type="radio"
                      value="Unread"
                      className="-oneX-radio"
                      name="messageActionFilter"
                      aria-label={
                        this.props.interactionsReducer
                          .officeMessagesUnreadCount === 1
                          ? this.props.interactionsReducer
                              .officeMessagesUnreadCount + " new message"
                          : this.props.interactionsReducer
                              .officeMessagesUnreadCount > 1
                          ? this.props.interactionsReducer
                              .officeMessagesUnreadCount + " new messages"
                          : "No new messages"
                      }
                    />
                    <label
                      htmlFor="unreadId"
                      title={
                        this.props.interactionsReducer
                          .officeMessagesUnreadCount > 0
                          ? "Filter New ( " +
                            this.props.interactionsReducer
                              .officeMessagesUnreadCount +
                            " )"
                          : "Filter New"
                      }>
                      {this.props.interactionsReducer
                        .officeMessagesUnreadCount > 0 &&
                        "( " +
                          this.props.interactionsReducer
                            .officeMessagesUnreadCount +
                          " ) "}
                      New
                      {this.props.interactionsReducer
                        .officeMessagesUnreadCount !== 0 && (
                        <svg
                          role="img"
                          aria-labelledby="unreadDot"
                          className="svg-size">
                          <title id="unreadDot">New Messages available</title>
                          <circle className="circle-styling" />
                        </svg>
                      )}
                    </label>
                  </div>
                  <div className="-oneX-radio-item">
                    <input
                      ref="Viewed"
                      id="viewedId"
                      type="radio"
                      value="Viewed"
                      className="-oneX-radio"
                      name="messageActionFilter"
                    />
                    <label htmlFor="viewedId" title="Filter Read">
                      Read
                    </label>
                  </div>
                  <div className="-oneX-radio-item">
                    <input
                      ref="Replied"
                      id="repliedId"
                      type="radio"
                      value="Replied"
                      className="-oneX-radio"
                      name="messageActionFilter"
                    />
                    <label htmlFor="repliedId" title="Filter Replied to">
                      Replied to
                    </label>
                  </div>
                  <div className="-oneX-radio-item">
                    <input
                      ref="Starred"
                      id="starredId"
                      type="radio"
                      value="Starred"
                      className="-oneX-radio"
                      name="messageActionFilter"
                    />
                    <label htmlFor="starredId" title="Filter Starred">
                      Follow-up
                    </label>
                  </div>
                  <div className="-oneX-radio-item">
                    <input
                      ref="Hidden"
                      id="hiddenId"
                      type="radio"
                      value="Hidden"
                      className="-oneX-radio"
                      name="messageActionFilter"
                    />
                    <label htmlFor="hiddenId" title="Filter Hidden">
                      Hidden
                    </label>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.authReducer.token,
    interactionsReducer: state.interactionsReducer,
    userInfoReducer: state.userInfoReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        mainAction,
        fetchInteractions,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterOptions);
