import axios from "axios";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig, uiRequestScope } from "./context/AuthConfig";

/**
 *  This interceptor listens for an exception and
 *  then redirect to OpenAM.
 *  https://github.com/axios/axios#interceptors
 */
export function register() {
  axios.interceptors.request.use(async (config) => {
    try {
      if (config.url.includes('/ui/') || config.url.includes('content')) {
        const msalInstance = new PublicClientApplication(msalConfig);
        const account = msalInstance.getAllAccounts()[0];
        let response = await msalInstance.acquireTokenSilent({
          ...uiRequestScope,
          account: account,
        });
        config.headers.Authorization = `Bearer ${response.accessToken}`;
        config.headers['X-CONSUMER_KEY'] = 'SFCONNECT_UI';
      }
      return config;
    }
    catch (error) {
      throw error;
    }
  }, error => {
    return Promise.reject(error);
  });

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response.status === 401) {
        axios.defaults.withCredentials = true;
        localStorage.removeItem("refreshLimit");
        localStorage.removeItem("token");
        window.location.href = process.env.REACT_APP_URL + '/.auth/logout';
      }
      return Promise.reject(error);
    }
  );
}
