import axios from "axios";
import { logUIErrors } from "./interactionsActions";
import {
  GET_CUSTOM_TEXT_TEMPLATES,
  ADD_CUSTOM_TEXT_TEMPLATES,
  UPDATE_CUSTOM_TEXT_TEMPLATES,
  DELETE_CUSTOM_TEXT_TEMPLATES,
  UPDATE_TEMPLATE_STATUS
} from "./types";

export const fetchCustomTemplates = (agentAssociateId) => (dispatch) => {
  axios
    .post(
      process.env.REACT_APP_APPSYNC_API_DYNAMIC_URL,
      {
        query: `query getTextTemplates($agentAssociateId: String!) {
        getTextTemplates(agentAssociateId: $agentAssociateId) {
          sort
          category
          title
          content
        }
      }`,
        variables: {
          agentAssociateId: `${agentAssociateId}`
        }
      }
    )
    .then((response) => {
      dispatch({
        payload: response.data.data.getTextTemplates,
        type: GET_CUSTOM_TEXT_TEMPLATES
      });
    })
    .catch((error) => {
      captureTemplateError(dispatch,agentAssociateId,error,"Error: Failed loading custom templates","Fetch Text Template")      
    });
};

export const addTextTemplate =
  (agentAssociateId, loggedInAssociateId, content, title, category) =>
  (dispatch) => {    
    axios
      .post(
        process.env.REACT_APP_APPSYNC_API_DYNAMIC_URL,
        {
          query: `mutation AddTemplate($agentAssociateId: String!, $loggedInAssociateId: String!, $category: String!, $title: String!, $content: String!) {
          addTextTemplate(agentAssociateId: $agentAssociateId, createdBy: $loggedInAssociateId, content: $content, title: $title, category: $category) {
            sort
            category
            title
            content
          }
        }`,
          variables: {
            agentAssociateId: `${agentAssociateId}`,
            loggedInAssociateId: `${loggedInAssociateId}`,
            category: `${category}`,
            title: `${title}`,
            content: `${content}`
          }
        },
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
      .then((response) => {
        dispatch({
          // validation error or new template value
          payload: response.data.errors || response.data.data.addTextTemplate,
          type: ADD_CUSTOM_TEXT_TEMPLATES
        });
        setTimeout(() => {
          dispatch({
            type: UPDATE_TEMPLATE_STATUS,
            payload: { status: "" }
          });
        }, 3000);
      })
      .catch((error) => {
        captureTemplateError(dispatch,agentAssociateId,error,"Error: Failed creating new template","Add Text Template")        
      });
  };

export const updateTextTemplate =
  (agentAssociateId, loggedInAssociateId, content, title, category) =>
  (dispatch) => {
    axios
      .post(
        process.env.REACT_APP_APPSYNC_API_DYNAMIC_URL,
        {
          query: `mutation UpdateTemplate($agentAssociateId: String!, $loggedInAssociateId: String!, $category: String!, $title: String!, $content: String!) {
          updateTextTemplate(agentAssociateId: $agentAssociateId, title: $title, content: $content, category: $category, updatedBy: $loggedInAssociateId) {
            sort
            category
            title
            content
          }
        }`,
          variables: {
            agentAssociateId: `${agentAssociateId}`,
            loggedInAssociateId: `${loggedInAssociateId}`,
            category: `${category}`,
            title: `${title}`,
            content: `${content}`
          }
        },
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
      .then((response) => {
        dispatch({
          payload: response.data.data.updateTextTemplate,
          type: UPDATE_CUSTOM_TEXT_TEMPLATES
        });
        setTimeout(() => {
          dispatch({
            type: UPDATE_TEMPLATE_STATUS,
            payload: { status: "" }
          });
        }, 3000);
      })
      .catch((error) => {
        captureTemplateError(dispatch,agentAssociateId,error,"Error: Failed updating template","Update Text Template")        
      });
  };

export const deleteTextTemplate =
  (agentAssociateId, title, category) => (dispatch) => {
    axios
      .post(
        process.env.REACT_APP_APPSYNC_API_DYNAMIC_URL,
        {
          query: `mutation deleteTemplate($agentAssociateId: String!, $category: String!, $title: String!)  {
          deleteTextTemplate(agentAssociateId: $agentAssociateId, title: $title, category: $category) {
            sort
            category
            title
          }
        }`,
          variables: {
            agentAssociateId: `${agentAssociateId}`,
            category: `${category}`,
            title: `${title}`
          }
        },
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
      .then((response) => {
        dispatch({
          payload: response.data.data.deleteTextTemplate,
          type: DELETE_CUSTOM_TEXT_TEMPLATES
        });
        setTimeout(() => {
          dispatch({
            type: UPDATE_TEMPLATE_STATUS,
            payload: { status: "" }
          });
        }, 3000);
      })
      .catch((error) => {
        captureTemplateError(dispatch,agentAssociateId,error,"Error: Failed deleting template","Delete Text Template")
      });
  };

export const clearCustomTemplateValues = (type, payload) => {
  return { type, payload };
};

function captureTemplateError(dispatch,agentAssociateId,error,_userMessage,_callerIdentifier){
    let err;
    if (error.response) {
      // The request was made and the server responded with a status code
      err = error.response;
      err.stack = undefined;
      err.config = undefined;
    } else if (error.request) {
      // The request was made but no response was received
      err = error.request;
    } else {
      // Something happened in setting up the request that triggered an error
      err = error.message;
    }
    dispatch(
      logUIErrors(agentAssociateId, err, _callerIdentifier, false)
    );
    dispatch({
      type: UPDATE_TEMPLATE_STATUS,
      payload: { status: _userMessage}
    });
}
