import axios from "axios";
import { trimExtension } from "../../utils/fileUtility";
import "regenerator-runtime/runtime";
import DOMPurify from "dompurify";
import {
  FETCH_INTERACTIONS,
  FETCH_MESSAGES,
  GET_UUID,
  GET_MMS_URL_SUCCESS,
  MARK_SPI_SUCCESS,
  PATCH_UPDATE_INTERACTION_RESOLVE_MULTIMATCH,
  PATCH_UPDATE_INTERACTION,
  PATCH_UPDATE_STARRED_INTERACTION,
  PUT_READ_INTERACTION,
  UPDATE_INTERACTIONS,
  GENERIC_ERROR,
  SEND_WEB_MESSAGE_SUCCESS,
  ARCHIVE_INTERACTION,
  CHANGE_FILTER_OPTIONS,
  PATCH_UPDATE_INTERACTION_PROSPECT_TO_CUSTOMER,
  ATTACH_TO_MESSAGE,
  PATCH_INTERACTION_HIDDEN_SETTING,
  PATCH_INTERACTION_ASSIGN_SETTING,
  REMOVE_INTERACTION,
  ADD_INTERACTION,
} from "./types";
import { toast } from "react-toastify";

export const fetchInteractions =
  (agentId, currentOfficeMembers, nextToken, filters) => (dispatch) => {
    let queryParameters = `agentId=${agentId}`;
    if (filters.fuzzySearch) {
      queryParameters += `&fuzzySearch=${encodeURIComponent(
        filters.fuzzySearch
      )}`;
    } else {
      if (nextToken) {
        queryParameters += `&nextToken=${nextToken}`;
      }
      if (filters.unread) {
        queryParameters += "&read=false&repliedTo=false";
      } else if (filters.read) {
        queryParameters += "&read=true&repliedTo=false";
      }
      if (filters.repliedTo) {
        queryParameters += "&repliedTo=true";
      }
      if (filters.followUp) {
        queryParameters += "&followUp=true";
      }
      if (filters.allMessages) {
        queryParameters += "&allMessages=true";
      }
      if (filters.hidden !== undefined) {
        queryParameters += `&hidden=${filters.hidden}`;
      }
      if (filters.assignments) {
        const assignments = filters.assignments
          .map((assignment) => assignment.associateId)
          .join(",");
        if (assignments.length) {
          queryParameters += `&assignedAssociateIds=${assignments}`;
        }
      }
      if (filters.returnUnassigned) {
        queryParameters += `&unassigned=${filters.returnUnassigned}`;
      }
      if(filters.returnInactiveAssignments) {
        let currentOfficeMembersString = "";
        currentOfficeMembersString = currentOfficeMembers.map(function(member) {
          return member['associateId'];
        }).join();
        queryParameters += `&inactiveAssignments=${filters.returnInactiveAssignments}&validOfficeMemberAssociateIds=${currentOfficeMembersString}`;
      }
    }

    axios
      .get(
        process.env.REACT_APP_AWS_API_URL + `interactions?${queryParameters}`
      )
      .then((response) => {
        dispatch({
          type: FETCH_INTERACTIONS,
          payload: response.data.interactions,
          currentOfficeMembers: currentOfficeMembers,
          nextToken: response.data.nextToken,
        });
        dispatch(mainAction(CHANGE_FILTER_OPTIONS, {}));
      })
      .catch((error) => {
        if (error.message.includes("304")) {
          return;
        }
        dispatch(logUIErrors(agentId, error, "Fetch Interactions", true));
      });
  };

export const markAsSPI =
  (messageId, index, attachmentIndex, attachmentMessage, agentId) => (dispatch) => {
    axios
      .patch(
        process.env.REACT_APP_AWS_API_URL +
        `messages/${messageId}/attachment`,
        {
          agentAssociateId: agentId,
          interactionId: attachmentMessage.interactionID,
          createdOn: attachmentMessage.createdOn,
          key: attachmentMessage.key
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(() => {
        dispatch({
          type: MARK_SPI_SUCCESS,
          index: index,
          attachmentIndex: attachmentIndex,
        });
      })
      .catch((error) => {
        dispatch(logUIErrors(agentId, error, "Mark as SPI", true));
      });
  };

export const getMmsUrl =
  (
    messageId,
    interactionId,
    createdOn,
    attachmentKey,
    agentId,
    index,
    attachmentIndex
  ) =>
    (dispatch) => {
      axios
        .get(
          process.env.REACT_APP_AWS_API_URL +
        `messages/${messageId}/attachment?agentId=${agentId}&interactionId=${interactionId}&createdOn=${createdOn}&key=${attachmentKey}`,
          {
            headers: {
              "X-CONSUMER_KEY": "SFCONNECT_UI"
            },
          }
        )
        .then((response) => {
          dispatch({
            type: GET_MMS_URL_SUCCESS,
            payload: response.data,
            index: index,
            attachmentIndex: attachmentIndex,
          });
        })
        .catch((error) => {
          dispatch(logUIErrors(agentId, error, "Get MMS URL", true));
        });
    };

export const fetchMessages =
  (
    interactionId,
    token,
    agentId,
    clientId,
    currentOfficeMembers,
    needToMoveIfFoundInHidden
  ) =>
  (dispatch) => {
    axios
      .get(
        process.env.REACT_APP_AWS_ROOT_URL +
          `/api/messages?interactionID=${interactionId}&limit=50&clientID=${clientId}&fromTimeStamp=${new Date().toUTCString()}`,
        {
          headers: {
            "X-CONSUMER_KEY": "SFCONNECT_UI",
            Authorization: `Bearer ${token}`,
            selectedAgentID: agentId,
          },
        }
      )
      .then((response) => {
        dispatch({
          type: FETCH_MESSAGES,
          payload: response.data,
          interactionId,
          currentOfficeMembers: currentOfficeMembers,
          needToMoveIfFoundInHidden,
        });
      })
      .catch((error) => {
        if (
          !(
            error.response &&
            error.response.data &&
            error.response.data.message.includes("no interaction found")
          )
        ) {
          dispatch(logUIErrors(agentId, error, "Fetch Messages", true));
        } else {
          dispatch({
            type: REMOVE_INTERACTION,
            interactionId,
          });

          toast.error(
            "The interaction you selected is archived. Consider refreshing your screen to ensure you have all of the latest data.",
            {
              position: toast.POSITION.TOP_CENTER,
            }
          );

          dispatch(
            logUIErrors(
              agentId,
              error,
              "User selected invalid interaction",
              false
            )
          );
        }
      });
  };
export const getUUID = (token, agentId, isParameterMissing) => (dispatch) => {
  axios
    .get(process.env.REACT_APP_AWS_ROOT_URL + "/api/messages/uuid", {
      headers: {
        "X-CONSUMER_KEY": "SFCONNECT_UI",
        Authorization: `Bearer ${token}`,
        selectedAgentID: agentId,
      },
    })
    .then((response) => {
      dispatch({
        type: GET_UUID,
        payload: response.data.uuid,
        isParameterMissing: isParameterMissing,
      });
    })
    .catch((error) => {
      dispatch(logUIErrors(agentId, error, "Get UUID", true));
    });
};

export const sendMessageForWeb =
  (message, agentId, loggedInAssociateId, loggedName, selectedTemplate) => (dispatch) => {
    axios
      .post(
        process.env.REACT_APP_AWS_API_URL + "texts",
        {
          agentAssociateId: message.ownerAgent.associateID,
          clientId: message.ownerClient.clientID,
          clientPhoneNumber: message.destination[0].data.phone,
          message: message.message.body,
          attachments: message.message.attachments,
          sender: {
            type: "associate",
            id: loggedInAssociateId,
            displayName: {
              name: loggedName,
            },
          },
          meta: {
            systemOfOrigin: "SF Connect UI",
            template: selectedTemplate,
          },
        },
        {
          headers: {
            selectedAgentID: agentId,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        dispatch({ type: SEND_WEB_MESSAGE_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        dispatch(logUIErrors(agentId, error, "Send Message from Web", true));
      });
  };

export const mainAction = (type, payload) => {
  return { type, payload };
};

export const patchInteractionResolveMultiMatch =
  (
    agentId,
    newClientId,
    oldClientID,
    interactionId,
    originClientType,
    newCustomerData
  ) =>
  (dispatch) => {
    axios
      .patch(
        process.env.REACT_APP_AWS_API_URL +
          `interactions/${interactionId}/clients/${oldClientID}`,
        {
          agentID: agentId,
          ownerClient: {
            clientID: newClientId,
            type: "client",
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (originClientType === "multi-match") {
          dispatch({
            type: PATCH_UPDATE_INTERACTION_RESOLVE_MULTIMATCH,
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        dispatch(logUIErrors(agentId, error, "Patch Update Interaction", true));
      });
  };

export const patchUpdateInteraction =
  (
    token,
    agentId,
    newClientId,
    oldClientID,
    interactionId,
    originClientType,
    newCustomerData
  ) =>
  (dispatch) => {
    axios
      .patch(
        process.env.REACT_APP_AWS_ROOT_URL +
          `/api/interactions/${interactionId}/clients/${oldClientID}/`,
        {
          ownerClient: {
            clientID: newClientId,
            type: "client",
          },
        },
        {
          headers: {
            "X-CONSUMER_KEY": "SFCONNECT_UI",
            Authorization: `Bearer ${token}`,
            selectedAgentId: agentId,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (originClientType === "prospect") {
          dispatch({
            type: PATCH_UPDATE_INTERACTION_PROSPECT_TO_CUSTOMER,
            payload: response.data,
          });
        } else if (originClientType === "client" && newCustomerData) {
          response.data.newCustomerDetails = newCustomerData;
          dispatch({ type: PATCH_UPDATE_INTERACTION, payload: response.data });
        }
      })
      .catch((error) => {
        dispatch(logUIErrors(agentId, error, "Patch Update Interaction", true));
      });
  };

export const patchUpdateStarredInteraction =
  (token, agentId, isStarred, clientID, interactionId) => (dispatch) => {
    axios
      .patch(
        process.env.REACT_APP_AWS_ROOT_URL +
          `/api/interactions/${interactionId}/clients/${clientID}/`,
        {
          starred: isStarred,
        },
        {
          headers: {
            "X-CONSUMER_KEY": "SFCONNECT_UI",
            Authorization: `Bearer ${token}`,
            selectedAgentId: agentId,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        dispatch({
          type: PATCH_UPDATE_STARRED_INTERACTION,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch(
          logUIErrors(agentId, error, "patch update starred interaction", true)
        );
      });
  };

export const putReadInteraction =
  (token, agentId, interactionId, clientId, unreadcount) => (dispatch) => {
    // Format for AXIOS PUT: axios.put(url[, data[, config]])
    axios
      .put(
        process.env.REACT_APP_AWS_ROOT_URL +
          `/api/interactions/${interactionId}/clients/${clientId}/read?unreadcount=${unreadcount}`,
        "",
        {
          headers: {
            "X-CONSUMER_KEY": "SFCONNECT_UI",
            Authorization: `Bearer ${token}`,
            selectedAgentId: agentId,
          },
        }
      )
      .then((response) => {
        dispatch({ type: PUT_READ_INTERACTION, payload: response.data });
      })
      .catch((error) => {
        dispatch(logUIErrors(agentId, error, "Put Read Interaction", true));
      });
  };

export const downloadDigitalCard = (agentId) => (dispatch) => {
  axios
    .get(
      process.env.REACT_APP_AWS_API_URL +
        `user/generatecard?associateID=${agentId}`
    )
    .then((response) => {
      //parsing the data to handle XSS threat
      const parser = new DOMParser();
      const sanitizedDocument = parser.parseFromString(
        response.data,
        "text/html"
      );
      //sanitizing the data to handle XSS threat
      const encodedData = DOMPurify.sanitize(sanitizedDocument.body.innerHTML);
      const downloadURL = window.URL.createObjectURL(new Blob([encodedData]));
      const anchor = document.createElement("a");
      anchor.href = downloadURL;
      anchor.setAttribute("download", "Digital-Contact-Card.vcf"); //or any other extension
      document.body.appendChild(anchor);
      anchor.click();
    })
    .catch((error) => {
      dispatch(
        logUIErrors(agentId, error, "Download Digital Card Interaction", true)
      );
    });
};

export const getClientInfoForNewClientInteraction =
  (
    token,
    messagePayload
    //loggedInAssociateId
  ) =>
  (dispatch) => {
    if (messagePayload.data.ownerClient.clientID !== "MORETHAN100") {
      axios
        .get(
          process.env.REACT_APP_AWS_ROOT_URL +
            `/api/clients/${messagePayload.data.ownerClient.clientID}`,
          {
            headers: {
              "X-CONSUMER_KEY": "SFCONNECT_UI",
              Authorization: `Bearer ${token}`,
              selectedAgentID: messagePayload.data.ownerAgent.associateID,
            },
          }
        )
        .then((response) => {
          dispatch(
            mainAction(UPDATE_INTERACTIONS, {
              data: messagePayload.data,
              newClientData: response.data,
            })
          );
          dispatch(mainAction(CHANGE_FILTER_OPTIONS, {}));
        })
        .catch((error) => {
          dispatch(
            logUIErrors(
              messagePayload.data.ownerAgent.associateID,
              error,
              "Get Client info for new Client interaction",
              true
            )
          );
        });
    } else {
      dispatch(
        mainAction(UPDATE_INTERACTIONS, {
          data: messagePayload.data,
        })
      );
      dispatch(mainAction(CHANGE_FILTER_OPTIONS, {}));
    }
  };

export const getMMInfoForNewClientInteraction =
  (
    token,
    messagePayload
    //loggedInAssociateId
  ) =>
  async (dispatch) => {
    let mmList = messagePayload.data.ownerClient.multiMatchClientIDs.map(
      (multiMatchClientId) =>
        axios
          .get(
            process.env.REACT_APP_AWS_ROOT_URL +
              `/api/clients/${multiMatchClientId}`,
            {
              headers: {
                "X-CONSUMER_KEY": "SFCONNECT_UI",
                Authorization: `Bearer ${token}`,
                selectedAgentID: messagePayload.data.ownerAgent.associateID,
              },
            }
          )
          .catch((error) => {
            dispatch(
              logUIErrors(
                messagePayload.data.ownerAgent.associateID,
                error,
                "Get MultiMatch info for new Client interaction",
                true
              )
            );
          })
    );
    let multiMatchInfoList = await Promise.all(mmList);
    dispatch(
      mainAction(UPDATE_INTERACTIONS, {
        data: messagePayload.data,
        multiMatchClientInfo: multiMatchInfoList.map(
          (clientInfo) => clientInfo.data
        ),
        // loggedInAssociateId: loggedInAssociateId,
      })
    );
    dispatch(mainAction(CHANGE_FILTER_OPTIONS, {}));
  };

export const archiveInteraction =
  (agentId, interactionId, clientId, lob, intent, archivedByAssociateId) =>
  (dispatch) => {
    axios
      .delete(
        process.env.REACT_APP_AWS_API_URL +
          `interactions/${interactionId}/clients/${clientId}/?agentId=${agentId}&archivedByAssociateId=${archivedByAssociateId}&lob=${lob}&intent=${intent}`
      )
      .then(() => {
        dispatch({ type: ARCHIVE_INTERACTION, payload: interactionId });
      })
      .catch((error) => {
        dispatch(logUIErrors(agentId, error, "Archive Interaction", true));
      });
  };

export const uploadOutgoingAttachment =
  (token, agentId, file, clientID) => async (dispatch) => {
    let presignedUrlContentType = file.type;
    if (file.name.includes(".vcf")) {
      presignedUrlContentType = "text/vcard";
    }
    let response = await axios
      .post(
        process.env.REACT_APP_AWS_ROOT_URL +
          `/api/messages/attachments/upload/presignedurl`,
        {
          clientID: clientID,
          attachments: [
            {
              fileName: trimExtension(file.name),
              contentType: presignedUrlContentType,
            },
          ],
        },
        {
          headers: {
            "X-CONSUMER_KEY": "SFCONNECT_UI",
            Authorization: `Bearer ${token}`,
            selectedAgentId: agentId,
            "Content-Type": "application/json",
          },
        }
      )
      .catch((error) => {
        throw error.response.status;
      });

    const s3signedUrl = response.data[0].presignedUrl;
    const bucket = response.data[0].bucket;
    const key = response.data[0].key;
    const fileName = key.split("/").pop();
    let uploadContentType = file.type;
    if (file.name.includes(".vcf")) {
      uploadContentType = "binary/octet-stream";
    }
    response = await axios
      .put(s3signedUrl, file, {
        headers: {
          "Content-Type": uploadContentType,
        },
      })
      .catch((error) => {
        throw error.response.status;
      });

    const attachments = [
      {
        Bucket: bucket,
        Key: key,
        fileName: fileName,
      },
    ];
    dispatch({ type: ATTACH_TO_MESSAGE, payload: attachments });
  };

export const logUIErrors =
  (agentId, error, actionInError, loadErrorPage, logType, interactionObj) =>
  async (dispatch) => {
    let message = "";

    if (logType === "INFO") {
      message = actionInError;
    } else if (loadErrorPage && error.response) {
      message =
        actionInError +
        " ; Request URL: " +
        (error.response.request != null
          ? error.response.request.responseURL
          : "") +
        " ; Trace ID: " +
        error.response.headers["trace-id"] +
        " ; Trace started: " +
        error.response.headers["trace-started"] +
        " ; Status Code: " +
        error.response.status +
        " ; Error: " +
        JSON.stringify(error.response.data);
    } else {
      message = actionInError + " ; Error: " + error;
    }

    axios
      .post(
        process.env.REACT_APP_AWS_API_URL + "logs",
        {
          sfconnectVersion: process.env.REACT_APP_VERSION || "Unknown",
          agentId: agentId,
          message: message,
          logType: logType,
          interaction: interactionObj,
        },
        {
          headers: {
            selectedAgentID: agentId,
            "Content-Type": "application/json",
          },
        }
      )
      .catch((error) => {
        dispatch({
          type: GENERIC_ERROR,
          actionInError: "Logging Errors",
          error,
        });
      });
    loadErrorPage &&
      dispatch({
        type: GENERIC_ERROR,
        actionInError: actionInError,
        error,
      });
  };

export const patchInteractionHiddenSetting =
  (agentId, interactionId, hidden, clientId, loggedInAssociateId) =>
  (dispatch) => {
    axios
      .patch(
        process.env.REACT_APP_AWS_API_URL +
          `interactions?agentAssociateId=${agentId}`,
        {
          interactionId,
          clientId,
          hidden: {
            value: hidden,
            hiddenBy: { type: "associate", id: loggedInAssociateId },
          },
        }
      )
      .then(() => {
        dispatch({
          type: PATCH_INTERACTION_HIDDEN_SETTING,
          interactionId: interactionId,
          hidden: {
            value: hidden,
            hiddenBy: { type: "associate", id: loggedInAssociateId },
          },
        });
        dispatch(mainAction(CHANGE_FILTER_OPTIONS, {}));
      })
      .catch((error) => {
        dispatch(
          logUIErrors(
            agentId,
            error,
            "Error updating interaction hidden status.",
            true
          )
        );
      });
  };

export const getInteractionForCustomer =
  (agentAssociateId, clientId, currentOfficeMembers) => (dispatch) => {
    axios
      .get(
        process.env.REACT_APP_AWS_API_URL +
          `agents/${agentAssociateId}/clients/${clientId}/interactions`
      )
      .then((response) => {
        dispatch({
          type: ADD_INTERACTION,
          payload: response.data,
          currentOfficeMembers,
        });
      })
      .catch((error) => {
        dispatch(
          logUIErrors(
            agentAssociateId,
            error,
            "Error getting interaction for customer",
            true
          )
        );
      });
  };

export const patchInteractionAssignSetting =
  (
    agentId,
    interactionId,
    assign,
    clientId,
    assignedAssociateId,
    loggedInAssociateId
  ) =>
  (dispatch) => {
    axios
      .patch(
        process.env.REACT_APP_AWS_API_URL +
          `interactions?agentAssociateId=${agentId}`,
        {
          interactionId,
          clientId,
          assigned: {
            value: assign,
            assignedAssociateId,
            assignedBy: { type: "associate", id: loggedInAssociateId },
          },
        }
      )
      .then(() => {
        dispatch({
          type: PATCH_INTERACTION_ASSIGN_SETTING,
          interactionId,
          assigned: {
            value: assign,
            assignedAssociateId,
            assignedBy: { type: "associate", id: loggedInAssociateId },
          },
        });
        dispatch(mainAction(CHANGE_FILTER_OPTIONS, {}));
      })
      .catch((error) => {
        dispatch(
          logUIErrors(
            agentId,
            error,
            "Error updating interaction assign status.",
            true
          )
        );
      });
  };
