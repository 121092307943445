import React from "react";
import { patchInteractionAssignSetting } from "../../redux/actions/interactionsActions";
import { useSelector, useDispatch } from "react-redux";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export default function Assign(props) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  let currentOfficeMembers = useSelector(
    (state) => state.userInfoReducer.currentOfficeMembers
  );
  const officeAdministrators = useSelector(
    (state) => state.officeSettingsReducer.officeAdministrators
  );

  const requireOfficeAdministratorToAssign = useSelector(
    (state) =>
      state.officeSettingsReducer.requireOfficeAdministratorToAssign || false
  );

  currentOfficeMembers = currentOfficeMembers.filter(
    (officeMember) => !officeMember.system
  );

  const {
    interactionId,
    agentId,
    clientId,
    loggedInAssociateId,
    hidden,
    assigned,
  } = props;
  let textToDisplayOnButton;

  for (let officeMember of currentOfficeMembers) {
    if (officeMember.associateId === loggedInAssociateId) {
      officeMember.associateName = "Me";
    }
  }

  if (assigned && assigned.value) {
    const assignedOfficeMember = currentOfficeMembers.find(
      ({ associateId }) => associateId === assigned.assignedAssociateId
    );
    if (assignedOfficeMember) {
      textToDisplayOnButton = assignedOfficeMember.associateName;
    } else {
      textToDisplayOnButton = "Unknown Team Member";
    }
  } else {
    textToDisplayOnButton = "Assign to";
  }

  const titleValue =
    assigned && assigned.value
      ? "click to unassign this interaction"
      : "click to assign this interaction";
  const classNameValue = hidden.value
    ? "-sfc-assignment-button -oneX-col-6"
    : "-sfc-assignment-button-left -oneX-col-9";

  const setAssignFlag = (assignedAssociateId, event) => {
    event.stopPropagation();
    dispatch(
      patchInteractionAssignSetting(
        agentId,
        interactionId,
        !assigned.value,
        clientId,
        assignedAssociateId,
        loggedInAssociateId
      )
    );
  };

  const handleMenuClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const createOfficeMemberMenu = () => {
    return currentOfficeMembers.map((officeMember, index) => {
      return (
        <MenuItem
          id={"assignMenuItem" + index}
          onClick={setAssignFlag.bind(this, officeMember.associateId)}>
          {officeMember.associateName}
        </MenuItem>
      );
    });
  };

  return (
    <>
      {requireOfficeAdministratorToAssign === true &&
      !(
        loggedInAssociateId === agentId ||
        officeAdministrators.includes(loggedInAssociateId)
      ) ? (
        <div className={classNameValue} title={titleValue}>
          <label>
            {assigned && assigned.value ? (
              <div>
                <strong>Assigned to: </strong>
                {textToDisplayOnButton}
              </div>
            ) : (
              <strong>Unassigned</strong>
            )}
          </label>
        </div>
      ) : (
        <div className={classNameValue} title={titleValue}>
          <Button
            className="-sfc-assign-button"
            id={"assignment-button"+interactionId}
            aria-haspopup="true"
            variant="outlined"
            disableElevation
            onClick={handleMenuClick}
            endIcon={<KeyboardArrowDownIcon />}
            disabled={
              requireOfficeAdministratorToAssign === true &&
              !(
                loggedInAssociateId === agentId ||
                officeAdministrators.includes(loggedInAssociateId)
              )
                ? true
                : false
            }>
            {textToDisplayOnButton}
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenuClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}>
            {assigned && assigned.value ? (
              <MenuItem
                onClick={setAssignFlag.bind(
                  this,
                  assigned.assignedAssociateId
                )}>
                Unassign
              </MenuItem>
            ) : (
              createOfficeMemberMenu()
            )}
          </Menu>
        </div>
      )}
    </>
  );
}
