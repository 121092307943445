import React, { Component } from "react";
import { tracks } from "components/Message/CorporateTemplates";
import { fetchCustomTemplates } from "../../redux/actions/customTemplatesActions";
import Emoji from "components/Message/emoji";
import WordTrackDrawer from "components/Message/WordTrackDrawer";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { putReadInteraction, downloadDigitalCard } from "../../redux/actions/interactionsActions";
import MediaQuery from "react-responsive";

class MsgAreaButtonBar extends Component {
  constructor(props) {
    super(props);
    this.markUnread = this.markUnread.bind(this);
    this.downloadDigitalCard = this.downloadDigitalCard.bind(this);    
  }

   openDrawer = () => {
    // Fetch custom templates and store in redux store
    this.props.actions.fetchCustomTemplates(
      this.props.officeAssociateId
    );
    window.oneX.Drawer.showDrawer("WordTrackDrawerBtn");
  };

  markUnread(event) {
    this.props.actions.putReadInteraction(
      this.props.token,
      this.props.officeAssociateId,
      this.props.currentInteraction.interactionId,
      this.props.currentInteraction.clientId,
      1
    );
  }

  downloadDigitalCard(event) {
    this.props.actions.downloadDigitalCard(
      this.props.officeAssociateId
    );
  }



  render() {
    return (
      <>
        {/* | Desktop View Port | */}
        <div className="msg-navbar">
          <div className="-oneX-row -oneX-no-gutters bottom-nav">
            <div className="-oneX-col">
              <button
                className={"-oneX-btn-small -oneX-btn-secondary dropbtn"}
                id="WordTrackDrawerBtn"
                data-onex-opensdrawer="WordTrackDrawer"
                onClick={this.openDrawer}                
              >
                Text Templates
              </button>
            </div>
            
            <WordTrackDrawer wordTracks={tracks}/>          
            <div className="-oneX-col">
              <button
                className="-oneX-btn-small -oneX-btn-secondary"
                data={"read-unread"}
                onClick={this.markUnread}
              >
                Mark Unread
              </button>
            </div>
            {/* | Desktop View Port | */}
            <MediaQuery minWidth={991}>
            <div className="-oneX-col">
              <button
                id="DigitalContactCardBtn"
                className="-oneX-btn-small -oneX-btn-secondary"
                data={"digitalcard"}
                onClick={this.downloadDigitalCard}
              >
                Digital Card
              </button>
            </div>
            </MediaQuery>            
            <div className="-oneX-col">
              <Emoji data={"emoji"} />
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.authReducer.token,
    officeAssociateId: state.userInfoReducer.officeAssociateId    
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        putReadInteraction,
        downloadDigitalCard,
        fetchCustomTemplates,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MsgAreaButtonBar);
