import {
  GET_CUSTOM_TEXT_TEMPLATES,
  ADD_CUSTOM_TEXT_TEMPLATES,
  UPDATE_CUSTOM_TEXT_TEMPLATES,
  DELETE_CUSTOM_TEXT_TEMPLATES,
  UPDATE_TEMPLATE_STATUS,
  SELECTED_TEMPLATE,
  CLEAR_CUSTOM_TEMPLATE
} from "../actions/types";

const createInitialState = () => {
  return {
    customTextTemplates: []
  };
};

export default function (state = createInitialState(), action) {
  switch (action.type) {
    case GET_CUSTOM_TEXT_TEMPLATES: {
      let customTextTemplates = {
        list: []
      };
      const groupedItems = action.payload.reduce((acc, item) => {
        if (!acc[item.category]) {
          acc[item.category] = [];
        }
        acc[item.category].push(item);
        return acc;
      }, {});

      for (let category in groupedItems) {
        customTextTemplates.list.push({
          category: category,
          items: groupedItems[category]
        });
      }
      let allowCreateTemplate = state.allowCreateTemplate;
      const maxCount = parseInt(
        process.env.REACT_APP_CUSTOM_TEMPLATE_MAX_COUNT,
        10
      );
      allowCreateTemplate = action.payload.length < maxCount ? true : false;
      return {
        ...state,
        customTextTemplates: customTextTemplates.list,
        customTemplatesCount: action.payload.length,
        allowCreateTemplate: allowCreateTemplate,
        templateCurrentStatus: "",
        templateAddStatus: allowCreateTemplate
          ? ""
          : "Max limit reached: Delete unused templates"
      };
    }
    case ADD_CUSTOM_TEXT_TEMPLATES: {
      let customTextTemplates = state.customTextTemplates;
      const customTemplatesCount = state.customTemplatesCount + 1;
      let status = state.templateAddStatus;
      if (action.payload.category) {
        const existingCategoryIndex = customTextTemplates.findIndex(
          (item) => item.category === action.payload.category
        );

        if (existingCategoryIndex !== -1) {
          const updatedList = [...customTextTemplates];
          updatedList[existingCategoryIndex].items.push(action.payload);
          updatedList[existingCategoryIndex].items.sort((a, b) =>
            a.title.localeCompare(b.title)
          );
          customTextTemplates = updatedList;
        } else {
          customTextTemplates = [
            ...customTextTemplates,
            {
              category: action.payload.category,
              items: [action.payload]
            }
          ];
        }
        status = "Template created successfully";
        let allowCreateTemplate = state.allowCreateTemplate;
        const maxCount = parseInt(
          process.env.REACT_APP_CUSTOM_TEMPLATE_MAX_COUNT,
          10
        );
        allowCreateTemplate = customTemplatesCount < maxCount ? true : false;

        return {
          ...state,
          customTextTemplates: customTextTemplates,
          customTemplatesCount: customTemplatesCount,
          allowCreateTemplate: allowCreateTemplate,
          templateAddStatus: status
        };
      } else {
        status = action.payload[0].message;
        return {
          ...state,
          templateAddStatus: status
        };
      }
    }
    case UPDATE_CUSTOM_TEXT_TEMPLATES: {
      let customTextTemplates = state.customTextTemplates;
      const updatedList = customTextTemplates.map((record) => {
        if (record.category === action.payload.category) {
          const updatedItems = record.items.map((item) => {
            if (item.title === action.payload.title) {
              return {
                ...item,
                content: action.payload.content
              };
            }
            return item;
          });
          return {
            ...record,
            items: updatedItems
          };
        }
        return record;
      });
      return {
        ...state,
        customTextTemplates: updatedList,
        templateCurrentStatus: "Template updated successfully"
      };
    }
    case DELETE_CUSTOM_TEXT_TEMPLATES: {
      let customTextTemplates = state.customTextTemplates;
      const customTemplatesCount = state.customTemplatesCount - 1;
      const updatedList = customTextTemplates
        .map((record) => {
          if (record.category === action.payload.category) {
            const updatedItems = record.items.filter(
              (item) => item.title !== action.payload.title
            );
            if (updatedItems.length === 0) {
              return null;
            }
            return {
              ...record,
              items: updatedItems
            };
          }
          return record;
        })
        .filter(Boolean);
      let allowCreateTemplate = state.allowCreateTemplate;
      const maxCount = parseInt(
        process.env.REACT_APP_CUSTOM_TEMPLATE_MAX_COUNT,
        10
      );
      allowCreateTemplate = customTemplatesCount < maxCount ? true : false;

      return {
        ...state,
        customTextTemplates: updatedList,
        templateCurrentStatus: "Template deleted successfully",
        customTemplatesCount: customTemplatesCount,
        allowCreateTemplate: allowCreateTemplate,
        templateAddStatus: allowCreateTemplate
          ? ""
          : "Max limit reached: Delete unused templates"
      };
    }
    case UPDATE_TEMPLATE_STATUS: {
      let customTextTemplates = state.customTextTemplates;
      const templateAddStatus =
        state.templateAddStatus && state.templateAddStatus.includes("Error")
          ? state.templateAddStatus
          : action.payload.status;
      if (action.payload.data) {
        customTextTemplates = action.payload.data;
      }
      return {
        ...state,
        customTextTemplates: customTextTemplates,
        templateAddStatus: state.allowCreateTemplate
          ? templateAddStatus
          : templateAddStatus.includes("Failed")
          ? "Error occured: Please try again"
          : "Max limit reached: Delete unused templates",
        templateCurrentStatus: action.payload.status
      };
    }
    case SELECTED_TEMPLATE: {
      let selectedTemplate = state.selectedTemplate;
      selectedTemplate = action.payload.selectedTemplate;
      return {
        ...state,
        selectedTemplate: selectedTemplate
      };
    }
    case CLEAR_CUSTOM_TEMPLATE: {
      return {
        ...state,
        customTextTemplates: [],
        allowCreateTemplate: false,
        templateCurrentStatus: "",
        templateAddStatus: "",
        customTemplatesCount: 0
      };
    }
    default:
      return state;
  }
}
